import { ReactNode } from "react";
import styled, { css } from "styled-components";

type Props = {
  className?: string;
  children?: ReactNode;
  margin?: boolean;
  padding?: boolean;
};

const padding = css`
  padding: ${props => {
    const { mainMargin, maxArticleWidth, maxContentWidth } = props.theme.dimen;
    const padding = `calc((${maxContentWidth} - ${maxArticleWidth}) / 2)`;
    return `${mainMargin} ${padding}`;
  }};
  box-sizing: border-box;

  @media only screen and (max-width: ${props =>
      props.theme.dimen.maxContentWidth}) {
    padding: 20px;
  }
`;

const margin = css`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Container = styled.section<Props>`
  width: 100%;
  max-width: ${props => props.theme.dimen.maxContentWidth};
  margin: 0 auto;
  box-shadow: 0 2px 5px ${props => props.theme.color.shadow};
  border-radius: 7px;

  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: border-radius, padding;

  background-color: ${props => props.theme.color.cardBackground};

  @media only screen and (max-width: ${props =>
      props.theme.dimen.maxContentWidth}) {
    border-radius: 0;
  }

  ${props => (props.padding ? padding : "")};
  ${props => (props.margin ? margin : "")};
`;
