import styled from "styled-components";
import { IconType } from "@react-icons/all-files";

const StyledLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  cursor: pointer;
  width: ${props => props.theme.dimen.headerHeight};

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${props => props.theme.color.linksHover};
    transform-origin: bottom right;
    transition-property: transform;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  }

  @media (hover: hover) {
    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  a {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    padding: 0 10px;

    color: ${props => props.theme.color.links};
    transition-property: color;
    transition-duration: 400ms;
    text-decoration: none;
  }

  @media (hover: hover) {
    &:hover {
      a {
        color: ${props => props.theme.color.linksHover};
      }
    }
  }
`;

const StyledIcon = styled.div`
  height: 1.5em;
  width: 1.5em;
`;

type Props = {
  icon: IconType;
  href: string;
  title: string;
};

export function ExternalLink(props: Props): JSX.Element {
  const { icon, href, title } = props;
  return (
    <StyledLogo>
      <a href={href} target="_blank" rel="nofollow noopener noreferrer">
        <StyledIcon as={icon} title={title} />
      </a>
    </StyledLogo>
  );
}
