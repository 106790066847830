import styled from "styled-components";
import { SiteMetadata } from "@joshuaavalon/model";

import { Title } from "./title";
import { Links } from "./links";

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-self: flex-start;
  width: 100%;

  background-color: ${props => props.theme.color.header};
  box-shadow: 0 1px 6px 0 ${props => props.theme.color.shadow};

  position: fixed;
  top: 0;
  height: ${props => props.theme.dimen.headerHeight};

  z-index: 10;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  max-width: ${props => props.theme.dimen.maxContentWidth};
`;

type Props = {
  siteMetadata: SiteMetadata;
};

export function Header(props: Props): JSX.Element {
  const { siteMetadata } = props;
  return (
    <StyledHeader>
      <StyledWrapper>
        <Title siteMetadata={siteMetadata} />
        <Links />
      </StyledWrapper>
    </StyledHeader>
  );
}
