import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";

const StyledImage = styled(GatsbyImage)`
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  max-width: 200px;
  width: 30%;
`;

type Props = {
  image: IGatsbyImageData;
  title: string;
};

export function Thumbnail(props: Props): JSX.Element {
  const { image, title } = props;
  return <StyledImage image={image} alt={title} />;
}
