import { ReactNode } from "react";
import styled, { ThemeProvider } from "styled-components";
import { defaultTheme, Global, Header } from "@joshuaavalon/component";

import { useSiteMetadata } from "../hook";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  min-height: calc(100vh - ${props => props.theme.dimen.headerHeight});
  padding-top: ${props => props.theme.dimen.headerHeight};
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  flex: 1;
`;

type Props = {
  children?: ReactNode;
  className?: string;
};

export function Layout(props: Props): JSX.Element {
  const { children, className } = props;
  const siteMetadata = useSiteMetadata();
  return (
    <ThemeProvider theme={defaultTheme}>
      <Global />
      <StyledLayout>
        <Header siteMetadata={siteMetadata} />
        <StyledMain className={className}>{children}</StyledMain>
      </StyledLayout>
    </ThemeProvider>
  );
}

export default Layout;
