import { SimplePost } from "@joshuaavalon/model";
import styled from "styled-components";

import { Tags } from "../tag";

const StyledTitle = styled.h2`
  text-align: center;

  color: ${props => props.theme.color.textPrimary};

  font-family: ${props => props.theme.font.header};
  font-size: 20px;

  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 5px;
`;

const StyledArticle = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tag {
    margin-top: 5px;
    margin-bottom: 0;
  }
`;

const StyledDate = styled.span`
  font-size: 13px;
  font-family: ${props => props.theme.font.system};

  color: ${props => props.theme.color.textSecondary};
  text-align: center;
`;

type Props = {
  post: SimplePost;
};

export function Article(props: Props): JSX.Element {
  const { post } = props;
  const { title, publishDate, tags } = post;
  return (
    <StyledArticle>
      <StyledTitle>{title}</StyledTitle>
      <StyledDate>{publishDate}</StyledDate>
      <Tags tagClassName="tag" tags={tags} />
    </StyledArticle>
  );
}
