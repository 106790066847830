import { Link } from "gatsby";
import styled from "styled-components";
import { SiteMetadata } from "@joshuaavalon/model";

const StyledTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  padding: 0 20px;

  font-family: ${props => props.theme.font.header};
  font-weight: 900;
  font-size: 20px;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.links};
  transition-property: color;
  transition-duration: 400ms;
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: ${props => props.theme.color.linksHover};
    }
  }
`;

type Props = {
  siteMetadata: SiteMetadata;
};

export function Title(props: Props): JSX.Element {
  const { title } = props.siteMetadata;
  return (
    <StyledLink to="/">
      <StyledTitle>{title}</StyledTitle>
    </StyledLink>
  );
}
