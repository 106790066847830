import { Link } from "gatsby";
import styled from "styled-components";
import { SimplePost } from "@joshuaavalon/model";

import { Thumbnail } from "./thumbnail";
import { Article } from "./article";

const StyleCard = styled.section`
  display: flex;
  flex-direction: row;

  position: relative;
  height: 100%;

  background-color: ${props => props.theme.color.cardBackground};

  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: box-shadow, transform;

  border-radius: 7px;
  box-shadow: 0 4px 8px ${props => props.theme.color.shadow};

  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);
      box-shadow: $shadow-hover;
    }
  }
`;

const StyleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  flex: 1;
  padding: 20px;
`;

type Props = {
  post: SimplePost;
};

export function PostCard(props: Props): JSX.Element {
  const { post } = props;
  const { path, thumbnail, title } = post;
  const { gatsbyImageData: image } = thumbnail;
  return (
    <Link to={path}>
      <StyleCard>
        <Thumbnail image={image} title={title} />
        <StyleContent>
          <Article post={post} />
        </StyleContent>
      </StyleCard>
    </Link>
  );
}
