import { createGlobalStyle } from "styled-components";

export const Global = createGlobalStyle`
a {
  text-decoration: none;
  color: $link-color;

  transition-property: color;
  transition-duration: 300ms;
  transition-timing-function: ease;

  @media (hover: hover) {
    &:hover {
      color: ${props => props.theme.color.linkHover};
    }
  }
}


body {
  color: ${props => props.theme.color.textPrimary};
  background-color: ${props => props.theme.color.background};
}

.gatsby-resp-image-figcaption {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;

  color: $text-secondary;
}

// Code

.gatsby-highlight {
  position: relative;
}


* {
  &::-webkit-scrollbar-track {
    background-color: ${props => props.theme.color.scrollbarBackground};
  }
  &::-webkit-scrollbar {
    background-color: ${props => props.theme.color.scrollbarBackground};

    &:vertical {
      width: 10px;
    }

    &:horizontal {
      height: 10px;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color:  ${props => props.theme.color.scrollbar};
  }
}

`;
