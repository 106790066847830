import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { SiteMetadata } from "@joshuaavalon/model";

type MetaProps = JSX.IntrinsicElements["meta"];
type LinkProps = JSX.IntrinsicElements["link"];
type Props = {
  description?: string;
  lang?: string;
  meta?: MetaProps[];
  title: string;
  link?: LinkProps[];
  keywords?: string[];
  pathname: string;
  image?: string;
  canonical?: string;
  siteMetadata: SiteMetadata;
};

export function Metadata(props: Props): JSX.Element {
  const {
    lang = "en",
    meta = [],
    title,
    link = [],
    keywords: propsKeywords = [],
    pathname,
    image,
    canonical,
    siteMetadata
  } = props;
  const { author, siteUrl } = siteMetadata;
  const { description = siteMetadata.description } = props;
  const { keywords = siteMetadata.keywords } = props;

  const metadata = useMemo(() => {
    const defaults: MetaProps[] = [
      { name: "description", content: description },
      { name: "keywords", content: keywords.join(",") },
      { property: "og:url", content: `${siteUrl}${pathname || "/"}` },
      { property: "og:site_name", content: title },
      { property: "og:title", content: siteMetadata.title },
      { property: "og:description", content: description },
      { property: "og:type", content: "website" },
      { name: "twitter:creator", content: author },
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description }
    ];
    if (author) {
      defaults.push({ name: "author", content: author });
    }
    if (image) {
      defaults.push({
        property: "og:image",
        content: `${siteUrl}${image}`
      });
      defaults.push({
        property: "twitter:image",
        content: `${siteUrl}${image}`
      });
      defaults.push({ name: "twitter:card", content: "summary_large_image" });
    } else {
      defaults.push({ name: "twitter:card", content: "summary" });
    }
    propsKeywords.forEach(content => {
      defaults.push({ property: "article:tag", content });
    });
    return defaults.concat(meta);
  }, [
    author,
    description,
    image,
    keywords,
    meta,
    pathname,
    propsKeywords,
    siteMetadata.title,
    siteUrl,
    title
  ]);

  const links = useMemo(() => {
    const defaults: LinkProps[] = [];
    if (canonical) {
      defaults.push({ ref: "canonical", href: canonical });
    }
    return defaults.concat(link);
  }, [canonical, link]);

  const htmlAttributes = useMemo(() => ({ lang }), [lang]);

  const titleTemplate = useMemo(() => `%s | ${siteMetadata.title}`, [
    siteMetadata.title
  ]);
  return (
    <Helmet
      htmlAttributes={htmlAttributes}
      title={title}
      titleTemplate={titleTemplate}
      meta={metadata}
      link={links}
    />
  );
}
