import { ReactNode } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: ${props => props.theme.font.system};

  position: relative;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;

  color: ${props => props.theme.color.link};

  background-color: hsl(0, 0%, 100%);
  border-radius: 5px;
  box-shadow: 0 2px 5px ${props => props.theme.color.shadow};

  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: box-shadow, transform;
`;

type Props = {
  link: string;
  children?: ReactNode;
};

export function HoverButton(props: Props): JSX.Element {
  const { link, children } = props;
  return <StyledLink to={link}>{children}</StyledLink>;
}
