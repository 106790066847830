import { useMemo } from "react";
import styled from "styled-components";
import { SimplePost } from "@joshuaavalon/model";

import { PostCard } from "./post-card";

const StyledGrid = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;

  width: 100%;
  max-width: ${props => props.theme.dimen.maxContentWidth};
  margin: 0 auto;
`;

type Props = {
  posts: SimplePost[];
  className?: string;
};

export function BlogGrid(props: Props): JSX.Element {
  const { posts, className } = props;
  const cards = useMemo(
    () =>
      posts.map(post => {
        const { path } = post;
        return <PostCard post={post} key={path} />;
      }),
    [posts]
  );
  return <StyledGrid className={className}>{cards}</StyledGrid>;
}
