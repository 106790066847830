import { MouseEventHandler, useCallback } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

const StyledTag = styled.div`
  display: inline-block;
  padding: 5px 10px;

  background-color: ${props => props.theme.color.tagBackground};
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;

  color: ${props => props.theme.color.tag};
  font-family: ${props => props.theme.font.system};
  font-size: 14px;

  cursor: pointer;

  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: background-color;

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.color.tagHoverBackground};
    }
  }
`;

type Props = {
  tag: string;
  className?: string;
};

export function Tag(props: Props): JSX.Element {
  const { tag, className } = props;
  const onClick: MouseEventHandler<HTMLElement> = useCallback(
    e => {
      e.preventDefault();
      navigate(`/tag/${tag}`);
    },
    [tag]
  );

  const onMouseEnter: MouseEventHandler<HTMLElement> = useCallback(() => {
    if (window && "___loader" in window) {
      const loader: any = window["___loader"];
      loader.enqueue(`/tag/${tag}`);
    }
  }, [tag]);
  return (
    <StyledTag
      className={className}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {tag}
    </StyledTag>
  );
}
