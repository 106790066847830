import { useMemo } from "react";
import styled from "styled-components";

import { Tag } from "./tag";

const StyledTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 5px;

    & + * {
      margin-left: 10px;
    }
  }
`;

type Props = {
  tags: string[];
  className?: string;
  tagClassName?: string;
};

export function Tags(props: Props): JSX.Element {
  const { tags, className, tagClassName } = props;
  const tagElments = useMemo(
    () => tags.map(tag => <Tag tag={tag} key={tag} className={tagClassName} />),
    [tagClassName, tags]
  );
  return <StyledTags className={className}>{tagElments}</StyledTags>;
}
