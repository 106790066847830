import styled, { createGlobalStyle } from "styled-components";
import { Post } from "@joshuaavalon/model";

const Prism = createGlobalStyle`
  pre[class*="language-"].line-numbers {
    position: relative;
    padding-left: 3.8em;
    counter-reset: linenumber;
  }

  pre[class*="language-"].line-numbers > code {
    position: relative;
    white-space: inherit;
  }

  .line-numbers .line-numbers-rows {
    position: absolute;
    pointer-events: none;
    top: 0;
    font-size: 100%;
    left: -3.8em;
    width: 3em; /* works for line-numbers below 1000 lines */
    letter-spacing: -1px;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .gatsby-highlight {
    .line-numbers-rows {
      min-width: 30px;
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      & > span {
        display: block;
        counter-increment: linenumber;

        &:before {
          content: counter(linenumber);
          color: #999;
          display: block;
          padding: 0 0.4em;
          text-align: right;
        }
      }
    }

    pre[class*="language-"].line-numbers {
      padding: 0;

      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-left: 2.8em;
    }
  }
`;

const NordTheme = createGlobalStyle`
  code[class*="language-"],
  pre[class*="language-"] {
    color: #f8f8f2;
    background: none;
    font-family: "Fira Code", Consolas, Monaco, "Andale Mono", "Ubuntu Mono",
      monospace;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }

  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;
  }

  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: #2e3440;
  }

  /* Inline code */
  :not(pre) > code[class*="language-"] {
    padding: 0.1em 0.3em;
    border-radius: 0.3em;
    white-space: normal;
  }

  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #636f88;
  }

  .token.punctuation {
    color: #81a1c1;
  }

  .namespace {
    opacity: 0.7;
  }

  .token.property,
  .token.tag,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #81a1c1;
  }

  .token.number {
    color: #b48ead;
  }

  .token.boolean {
    color: #81a1c1;
  }

  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #a3be8c;
  }

  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string,
  .token.variable {
    color: #81a1c1;
  }

  .token.atrule,
  .token.attr-value,
  .token.function,
  .token.class-name {
    color: #88c0d0;
  }

  .token.keyword {
    color: #81a1c1;
  }

  .token.regex,
  .token.important {
    color: #ebcb8b;
  }

  .token.important,
  .token.bold {
    font-weight: bold;
  }

  .token.italic {
    font-style: italic;
  }

  .token.entity {
    cursor: help;
  }
`;

const StyledArticle = styled.article`
  width: 100%;

  font-family: ${props => props.theme.font.body};
  text-align: justify;
  text-justify: inter-word;

  .gatsby-highlight {
    pre {
      border-radius: 0.3em;

      &::-webkit-scrollbar-track,
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        &:vertical {
          border-top-right-radius: 0.3em;
          border-bottom-right-radius: 0.3em;
        }

        &:horizontal {
          border-bottom-left-radius: 0.3em;
          border-bottom-right-radius: 0.3em;
        }
      }
    }
  }

  :not(pre) > code {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 0 0.5em;

    border-width: 1px;
    border-style: solid;
  }

  .gatsby-resp-image-wrapper,
  .gatsby-resp-image-figure {
    .gatsby-resp-image-background-image,
    img {
      border-radius: 7px;
    }
  }

  blockquote {
    position: relative;

    padding: 5px 30px;
    margin: 0 0 25px 0;
    border-left: 0;

    font-style: italic;
    line-height: 25px;
  }

  blockquote:before {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    width: 6px;
    height: 100%;

    background: $blockquote-color;
    border-radius: 6px;
  }

  .gatsby-highlight > pre {
    max-height: 480px;
    overflow-x: auto;
    overflow-y: auto;
  }

  p > img {
    display: block;
    margin: auto;
    border-radius: 7px;

    max-height: 650px;
  }
`;

type Props = {
  post: Post;
};

export function Article(props: Props): JSX.Element {
  const { post } = props;
  const { html } = post;
  return (
    <>
      <Prism />
      <NordTheme />
      <StyledArticle dangerouslySetInnerHTML={{ __html: html }} />
    </>
  );
}
