import { useMemo } from "react";
import styled from "styled-components";

import { HoverButton } from "./hover-button";

const StyledPagination = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  margin: 20px 0;
`;

type Props = {
  next?: string;
  prev?: string;
  className?: string;
};
export function Pagination(props: Props): JSX.Element {
  const { next, prev, className } = props;
  const prevPage = useMemo(
    () => (prev ? <HoverButton link={prev}>Prev</HoverButton> : null),
    [prev]
  );
  const nextPage = useMemo(
    () => (next ? <HoverButton link={next}>Next</HoverButton> : null),
    [next]
  );
  return (
    <StyledPagination className={className}>
      {prevPage}
      {nextPage}
    </StyledPagination>
  );
}
