import { DiscussionEmbed, CommentCount as DisqusCount } from "disqus-react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  min-height: 300px;
`;

type Props = {
  pathname: string;
  origin: string;
  title: string;
};

export function Comment(props: Props): JSX.Element {
  if (process.env.NODE_ENV === "development") {
    return <StyledWrapper />;
  }
  const { pathname, origin, title } = props;
  const disqusConfig = {
    url: origin + pathname,
    identifier: pathname,
    title
  };
  return (
    <StyledWrapper>
      <DiscussionEmbed shortname="joshua-avalon-blog" config={disqusConfig} />
    </StyledWrapper>
  );
}

export function CommentCount(props: Props): JSX.Element {
  const { pathname, origin, title } = props;
  const disqusConfig = {
    url: origin + pathname,
    identifier: pathname,
    title
  };
  return <DisqusCount shortname="joshua-avalon-blog" config={disqusConfig} />;
}
