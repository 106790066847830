import { graphql, useStaticQuery } from "gatsby";
import { SiteMetadataQuery } from "@joshuaavalon/client-graphql";
import { SiteMetadata } from "@joshuaavalon/model";

export function useSiteMetadata(): SiteMetadata {
  const { site } = useStaticQuery<SiteMetadataQuery>(graphql`
    query siteMetadata {
      site {
        siteMetadata {
          title
          description
          author
          keywords
          siteUrl
        }
      }
    }
  `);
  const { title, description, author, keywords, siteUrl } =
    site?.siteMetadata || {};
  return {
    title: title || "",
    description: description || "",
    author: author || "",
    keywords: keywords || [],
    siteUrl: siteUrl || ""
  };
}
