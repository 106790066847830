import styled, { keyframes } from "styled-components";
import { FaGithub } from "@react-icons/all-files/fa/FaGithub";
import { FaGitlab } from "@react-icons/all-files/fa/FaGitlab";
import { FaMediumM } from "@react-icons/all-files/fa/FaMediumM";

import { ExternalLink } from "./external-link";

const drop = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -25px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const StyledLinks = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;

  font-size: 13px;
  font-family: ${props => props.theme.font.header};

  animation: ${drop} 0.3s ease-in-out forwards;

  .logo {
  }
`;

export function Links(): JSX.Element {
  return (
    <StyledLinks>
      <ExternalLink
        href="https://github.com/joshuaavalon"
        icon={FaGithub}
        title="GitHub"
      />
      <ExternalLink
        href="https://gitlab.com/joshuaavalon"
        icon={FaGitlab}
        title="GitLab"
      />
      <ExternalLink
        href="https://medium.com/@joshuaavalon"
        icon={FaMediumM}
        title="Medium"
      />
    </StyledLinks>
  );
}
