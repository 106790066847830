import { DefaultTheme } from "styled-components";

const nord0 = "#2e3440";
const nord1 = "#3b4252";
const nord2 = "#434c5e";
const nord3 = "#4c566a";
const nord4 = "#d8dee9";
// const nord5 = "#e5e9f0";
const nord6 = "#eceff4";
const blue = " #81a1c1";
const darkBlue = " #5e81ac";

export const defaultTheme: DefaultTheme = {
  font: {
    body: '"Roboto", sans-serif',
    system: '"Montserrat", sans-serif',
    header: '"Comfortaa", sans-serif'
  },
  color: {
    textPrimary: nord0,
    textSecondary: nord1,
    background: nord6,
    scrollbar: nord3,
    scrollbarBackground: nord4,
    header: nord6,
    shadow: nord4,
    links: nord0,
    linksHover: nord3,
    link: darkBlue,
    linkHover: blue,
    blockquote: nord3,
    cardBackground: "white",
    tag: nord6,
    tagBackground: nord2,
    tagHoverBackground: nord3
  },
  dimen: {
    maxContentWidth: "1200px",
    maxArticleWidth: "800px",
    headerHeight: "56px",
    mainMargin: "20px"
  }
};
